import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { graphql } from "gatsby";



import Layout from '../components/layout'
import { CallToAction, FeaturedWineries, WineryCount } from "../components/sections";
import { Geocoder } from "../components/composites";
import { BackgroundSection } from "../components/primitives";


import {countryRoute, GatsbyLink, tagRoute} from "../utils";
import { usePrimeFixedImage } from "../utils/hooks";

import './index.scss'


const IndexPage = ({data}) => {
    const {
        directus: {
            articles,
            countries,
            home_page,
            tags
        }
    } = data;

    const featuredTag = tags.filter( ({id}) => id === '47d5f7a8-0a17-4f12-b91d-ef75fa877ca7')[0] ?? tags[0];
    const otherTags = tags.filter( ({slug}) => slug !== featuredTag.slug)

    const getPopularImage = (name) => {
        let image = countries.find((t) => t.name === name);
        return image?.featured_image ?? '';
    };

    usePrimeFixedImage()

    return(
        <Layout className='index-page' currentPage='home' title='VIND - Explore Wineries Worldwide'>
            <BackgroundSection
                className='section-hero'
                image={home_page.featured_image}
                overlayColor='rgba(0,0,0,.40)'
                isGatsbyImage
            >
                <div className='section-content title-section'>
                    <h1 className='vind-title'>
                        {home_page?.title ?? 'Helping you find and experience the world\'s best wineries test'}
                    </h1>
                    <Geocoder id='page-geocoder' showButton />
                </div>
            </BackgroundSection>

            <WineryCount
                intro={home_page?.intro}
            />

            <FeaturedWineries/>

            <section className='section-popular-location'>
                <div className='bg-text'>Popular Destinations</div>
                <BackgroundSection
                    className='bg-image-base'
                    image={getPopularImage(countries[0]?.name ?? '')}
                    overlayColor='rgba(0,0,0,.10)'
                    isGatsbyImage
                />

                {/*<div className='bg-images'>*/}
                {/*{countries.map( ({name}, index) => (*/}
                {/*    <BackgroundSection*/}
                {/*        key={`list-popupar-bg-images-${index}`}*/}
                {/*        className='bg-image-base'*/}
                {/*        image={getPopularImage(countries[0]?.name ?? '')}*/}
                {/*        overlayColor='rgba(0,0,0,.10)'*/}
                {/*        id={`loc${index+1}`}*/}
                {/*    />*/}
                {/*))}*/}
                {/*</div>*/}

                <div className='shell'>
                    <ul className='list-popupar-locations'>
                    {countries.map( ({ name, slug, wineries }, index) => (
                        <li key={`list-popupar-list-${index}`}>
                            <GatsbyLink href={countryRoute(slug)} data-image={`loc${index+1}`}>
                                {name}
                                <span>{wineries?.length ?? ''}</span>
                            </GatsbyLink>
                        </li>
                    ))}
                    </ul>
                </div>
            </section>

            <section className='section-list-blog-articles'>
                <div className='shell'>
                    <ul className='list-blog-articles'>
                        {articles.map( ({ author, featured_image, introduction, title, wordpress_link }, index) => {
                            const articleClasses = classNames(
                                'artilce-item',
                                index === 0 && 'prime'
                            )

                            return (
                                <li className={articleClasses} key={`article-image-${index}`}>
                                    <BackgroundSection
                                        className='article-image'
                                        image={featured_image ?? ''}
                                        isGatsbyImage
                                    >
                                        <GatsbyLink href={wordpress_link}>
                                            <span className='sr-only'>View the {title} article</span>
                                        </GatsbyLink>
                                    </BackgroundSection>
                                    <div className='article-content'>
                                        <h4 className='article-title'>
                                            <GatsbyLink href={wordpress_link}>
                                                {title}
                                            </GatsbyLink>
                                        </h4>

                                        <p className='article-text'>{introduction}</p>

                                        <p className='article-author'>
                                            <small>{author}</small>
                                        </p>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>

                    <div className='section-actions'>
                        <GatsbyLink href='https://journal.vind.wine' className='btn'>
                            Discover more stories
                        </GatsbyLink>
                    </div>
                </div>
            </section>

            <section className='section-be-inspired'>
                <div className='shell-right shell'>
                    <div className='section-head'>
                        <h6 className='section-title'>Be Inspired</h6>
                    </div>

                    <div className='section-body' id='inspired-section-body'>
                        <div className='section-image'>
                            <div className='inspired-prime-image' id='inspired-prime-image'>
                                <GatsbyLink href={tagRoute(featuredTag.slug)}>
                                    <BackgroundSection
                                        className='image'
                                        image={featuredTag?.featured_image ?? ''}
                                        overlayColor='rgba(0,0,0,.40)'
                                        isGatsbyImage
                                    />
                                    <span className='text'>{featuredTag.name}</span>

                                </GatsbyLink>
                            </div>
                        </div>

                        <div className='section-gallery'>
                            <ul className='list-inspired-gallery'>
                                {otherTags.map( ({featured_image, name, slug}, index) => (
                                    <li key={`list-inspired-image-${index}`}>
                                        <GatsbyLink href={tagRoute(slug)}>
                                            <BackgroundSection
                                                className='image'
                                                image={featured_image ?? ''}
                                                overlayColor='rgba(0,0,0,.40)'
                                                isGatsbyImage
                                            />
                                            <span className='text'>{name}</span>
                                        </GatsbyLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <CallToAction
                bgImage={home_page?.call_to_action_image ?? ''}
                ctaText={home_page?.call_to_action_text}
            />
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

IndexPage.defaultProps = {
}

export const IndexPageQuery = graphql`
  query {
    directus {
        home_page {
          call_to_action_text
          call_to_action_image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          intro
          title
          featured_image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      articles(
        limit: 4,
        sort: ["-publish_date"]
       ){
        author
        featured_image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
        }
        introduction
        title
        wordpress_link
      }
      countries(
          filter: {featured: {_eq: true} }
       ) {
        name
        slug
        featured_image {
          id
          imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
        }
        wineries(
          limit: -1
        ) {
          id
        }
      }
      tags(
          limit: 6
          filter: {featured: {_eq: true} }
          sort: "sort"
       )  {
        id
        name
        slug
        featured_image {
          id
          imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
        }
      }
    }
  }
`

export default IndexPage